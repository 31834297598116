<template>
  <div id="addNotification" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">EDITAR NOTIFICACIÓN</v-row>
      <v-container fluid class="addForms" style="margin-top: 2%">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" class="ml-5 formularios">
            <v-row>
              <!--Razon Social-->
              <label for="reason">Razón social</label>
              <select
                name="reason"
                id="reason"
                v-model="notificacion.empresaId"
                @change="obtenerAreas()"
              >
                <option selected disabled value="0">Selecciona Razon</option>
                <option
                  v-for="reason in enterprises"
                  :key="reason.id"
                  v-bind:value="reason.id"
                >
                  {{ reason.razonSocial }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Area-->
              <label for="area">Área</label>
              <select
                name="area"
                id="area"
                v-model="notificacion.areaId"
                @change="obtenerPuestos()"
              >
                <option selected disabled value="0">Selecciona Area</option>
                <option
                  v-for="area in areas"
                  :key="area.id"
                  v-bind:value="area.id"
                >
                  {{ area.nombreArea }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Puesto-->
              <label for="job">Puesto</label>
              <select
                name="job"
                id="job"
                v-model="notificacion.puestoId"
                @change="obtenerEmpleados()"
              >
                <option selected disabled value="0">Selecciona Puesto</option>
                <option v-for="job in jobs" :key="job.id" v-bind:value="job.id">
                  {{ job.nombrePuesto }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Empleados-->
              <label for="employee">Empleado</label>
              <select
                name="employee"
                id="employee"
                v-model="notificacion.empleadoId"
              >
                <option selected disabled value="0">Selecciona Empleado</option>
                <option
                  v-for="employee in employees"
                  :key="employee.id"
                  v-bind:value="employee.id"
                >
                  {{ employee.nombre }} {{ employee.apellidoPaterno }}
                  {{ employee.apellidoMaterno }}
                </option>
              </select>
            </v-row>
            <v-row>
              <!--Comunicado-->
              <label for="event">Comunicado</label>
              <input
                type="text"
                name="event"
                id="event"
                placeholder="Titulo del Comunicado"
                class="inputs"
                autocomplete="false"
                v-model="notificacion.tituloComunicado"
                maxlength="50"
                @keypress="alfaNumerico($event)"
              />
              <br />
              <!--No delete target label-->
              <label></label>
              <textarea
                id="eventText"
                placeholder="Editor de texto..."
                v-model="notificacion.descripcionComunicado"
                @keypress="alfaNumerico($event)"
              ></textarea>
            </v-row>
            <v-row>
              <!--Redacto-->
              <label for="person">Redacto</label>
              <input
                type="text"
                name="person"
                id="person"
                class="inputs"
                autocomplete="false"
                :value="
                  notificacion.empleadoEnvia.nombre +
                  ' ' +
                  notificacion.empleadoEnvia.apellidoPaterno
                "
                maxlength="5"
                @keypress="isNumber($event)"
                disabled
              />
            </v-row>
            <v-row>
              <!--Fecha inicio-->
              <label for="startDate">Fecha de Inicio</label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="notificacion.fechaInicio"
                maxlength="20"
                :min="fecha_minimo"
                @keypress="alfaNumerico($event)"
                @blur="validacionFechas()"
              />
            </v-row>
            <v-row>
              <!--fecha fin-->
              <label for="finishDate">Fecha de Fin</label>
              <input
                type="date"
                name="finishDate"
                id="finishDate"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                v-model="notificacion.fechaFin"
                :min="fecha_minimo"
                maxlength="20"
                @keypress="alfaNumerico($event)"
                @blur="validacionFechas()"
              />
            </v-row>
            <!-- <v-row
              v-if="
                notificacion.estatusId == 1 ||
                notificacion.estatusId == null
              "
            >              
              <label for="activa">Listo para publicar</label>
              <v-checkbox
                name="activa"
                id="activa"
                autocomplete="false"
                v-model="activa"
                size="20"
              ></v-checkbox>              
            </v-row> -->
            <v-row
              v-if="
                notificacion.estatusId == 1 || notificacion.estatusId == null
              "
            >
              <v-col cols="12" sm="5" md="5" lg="5">
                <label for="activa">Listo para publicar</label></v-col
              ><v-col cols="12" sm="7" md="7" lg="7">
                <v-checkbox
                  v-model="activa"
                  class="mt-n2"
                  color="orange"
                  name="activa"
                  id="activa"
                  autocomplete="false"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="7" align-self="center">
            <!--Acciones-->
            <button class="botonAmarillo mt-5 ml-6" @click="validacion()">
              Guardar
            </button>
            <router-link to="/notification" id="backNotification">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="280">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de modificar la notificación?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Notificación</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="regresar()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      dialog: false,
      expiration: false,
      titulo:"",
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      enterprises: [],
      areas: [],
      jobs: [],
      employees: [],
      todosEmpleados: [],
      sender: 1,
      person: 1,
      enterprise: 0,
      area: 0,
      job: 0,
      employee: 0,
      finishDate: "",
      startDate: "",
      politicaId: 0,
      notificacion: [],
      activa: false,
      fecha_minimo: "",
    };
  },
  methods: {
    validacionFechas() {
      if (
        this.notificacion.fechaInicio != "" &&
        this.notificacion.fechaFin == ""
      )
        console.log("solo se lleno fecha inicial");
      if (
        this.notificacion.fechaFin != "" &&
        this.notificacion.fechaInicio == ""
      )
        console.log("solo se lleno fecha final");
      if (
        this.notificacion.fechaInicio != "" &&
        this.notificacion.fechaFin != ""
      ) {
        console.log("ambas fechas tienen valors");
        if (this.notificacion.fechaInicio > this.notificacion.fechaFin) {
          this.titulo = "Fechas Solicitadas";
          this.respuesta =
            "La fecha de inicio debe ser diferente, favor de corregirlo";
          this.error = true;
        }
      }
    },
    regresar() {
      this.confirmation = false;
      this.$router.push("/notification");
    },
    guardar() {
      this.advice = false;
      if (this.activa) {
        this.notificacion.estatusId = 2;
      }
      this.show = true;
      axios
        .put(
          Server + "/notificaciones/" + this.notificacion.id,
          this.notificacion,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          //this.notificacion = [];
          this.show = false;
          this.respuesta = "La notificacion fue actualizada con éxito";
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.notificacion = [];
            this.respuesta = "La notificacion no fue actualizada";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      if (
        this.notificacion.empresaId == "" ||
        this.notificacion.empresaId == " "
      ) {
        this.errors.push("Se debe seleccionar la razón social.");
      }
      if (this.notificacion.areaId == 0) {
        this.errors.push("Se debe seleccionar el area.");
      }
      if (this.job != 0 && this.notificacion.empleadoId == 0) {
        this.errors.push("Se debe seleccionar el empleado.");
      }
      if (
        this.notificacion.empleadoEnviaId == "" ||
        this.notificacion.empleadoEnviaId == " "
      ) {
        this.errors.push("Se debe especifir persona quien redacto.");
      }
      if (
        this.notificacion.tituloComunicado == "" ||
        this.notificacion.tituloComunicado == " "
      ) {
        this.errors.push("Se debe título de comunicado.");
      }
      if (
        this.notificacion.descripcionComunicado == "" ||
        this.notificacion.descripcionComunicado == " "
      ) {
        this.errors.push("Se debe especificar texto del comunicado.");
      }
      if (
        this.notificacion.fechaInicio == "" ||
        this.notificacion.fechaInicio == " "
      ) {
        this.errors.push("Se debe especificar fecha de inicio del comunicado.");
      }
      if (
        this.notificacion.fechaFin == "" ||
        this.notificacion.fechaFin == " "
      ) {
        this.errors.push("Se debe especificar fecha de fin del comunicado.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.titulo = "Notificación";
        this.error = true;
      }
    },
    aux() {
      this.advice = false;
      this.notificacion = [];
      this.$router.push("/notification");
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/notificaciones/" + localStorage.idNotificacion, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          localStorage.idNotificacion = "";
          this.notificacion = response.data;
          this.notificacion.fechaInicio = this.notificacion.fechaInicio.substr(
            0,
            10
          );
          this.notificacion.fechaFin = this.notificacion.fechaFin.substr(0, 10);
          this.areas = [];
          if (this.notificacion.empleado != null) {
            this.job = this.notificacion.empleado.puestoId;
          }
          if (
            this.notificacion.areaId == null &&
            this.notificacion.empleado != null
          ) {
            this.notificacion.areaId = this.notificacion.empleado.areaId;
          }
          if (
            this.notificacion.area == null &&
            this.notificacion.empleado != null
          ) {
            this.notificacion.area = this.notificacion.empleado.area;
          }
          this.show = true;
          //console.log("areas");
          axios
            .get(Server + "/areas", {
              headers: {
                Authorization: localStorage.token,
              },
            })
            .then((response) => {
              //console.log(response);
              var auxAreas = response.data;
              auxAreas.forEach((value, index) => {
                if (value.empresaId == this.notificacion.empresaId) {
                  this.areas.push({
                    id: value.id,
                    nombreArea: value.nombreArea,
                  });
                }
              });
              this.show = false;
              //console.log(this.areas);
            })
            .catch((e) => {
              //console.log(e);
              this.show = false;
              if (e.response.status == 401) {
                this.expiration = true;
              }
            });
          this.jobs = [];
          //console.log("puestos");
          this.show = true;
          axios
            .get(Server + "/puestos", {
              headers: {
                Authorization: localStorage.token,
              },
            })
            .then((response) => {
              //console.log(response);
              var auxAreas = response.data;
              auxAreas.forEach((value, index) => {
                if (
                  value.empresaId ==
                  this.notificacion
                    .empresaId /* &&
                  value.areaReportaId == this.notificacion.empleado.areaId */
                ) {
                  this.jobs.push({
                    id: value.id,
                    nombrePuesto: value.nombrePuesto,
                  });
                }
              });
              //console.log(this.jobs);
              this.show = false;
            })
            .catch((e) => {
              //console.log(e);
              this.show = false;
              if (e.response.status == 401) {
                this.expiration = true;
              }
            });
          this.employees = [];
          this.show = true;
          axios
            .get(Server + "/empleados", {
              headers: {
                Authorization: localStorage.token,
              },
            })
            .then((response) => {
              //console.log(response);
              var todosEmpleados = response.data;
              todosEmpleados.forEach((element) => {
                if (
                  element.empresaId == this.notificacion.empresaId &&
                  element.puestoId == this.job &&
                  element.areaId == this.notificacion.areaId
                ) {
                  this.employees.push(element);
                }
              });
              this.show = false;
              //console.log(this.employees);
            })
            .catch((e) => {
              console.log(e);
              this.show = false;
              if (e.response.status == 401) {
                this.expiration = true;
              }
            });
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          localStorage.idNotificacion = "";
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerAreas() {
      this.areas = [];
      //this.notificacion.puestoId = 0;
      this.notificacion.areaId = 0;
      this.notificacion.empleadoId = 0;
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.notificacion.empresaId) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
          //console.log(this.areas);
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerPuestos() {
      this.jobs = [];
      //this.notificacion.empleado.puestoId =0;
      this.notificacion.empleadoId = 0;
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (
              value.empresaId == this.notificacion.empresaId &&
              value.areaReportaId == this.notificacion.areaId
            ) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
          //console.log(this.jobs);
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerEmpleados() {
      this.employees = [];
      //this.notificacion.empleadoId = 0;
      this.show = true;
      axios
        .get(Server + "/empleados", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var todosEmpleados = response.data;
          todosEmpleados.forEach((element) => {
            if (
              element.empresaId == this.notificacion.empresaId &&
              element.puestoId == this.job &&
              element.areaId == this.notificacion.areaId
            ) {
              this.employees.push(element);
            }
          });
          this.show = false;
          //console.log(this.employees);
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    todosEmpleadosEmpresa() {
      this.todosEmpleados = [];
      this.person = 0;
      this.show = true;
      axios
        .get(Server + "/empleados", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var empleados = response.data;
          empleados.forEach((element) => {
            if (element.empresaId == this.notificacion.empresaId) {
              this.todosEmpleados.push(element);
            }
          });
          this.show = false;
          //console.log(this.employees);
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    fechasMinimo() {
      var fecha = new Date();
      var anio = fecha.getFullYear();
      var dia = fecha.getDate();
      var _mes = fecha.getMonth(); //0 al 11
      _mes = _mes + 1; //de 1 al 12
      if (_mes < 10) {
        //agrega un 0
        var mes = "0" + _mes;
      } else {
        var mes = _mes.toString();
      }
      this.fecha_minimo = anio + "-" + mes + "-" + dia; // Nueva variable
    },
  },
  created() {
    this.fechasMinimo();
    this.listar();
  },
};
</script>